import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Offcanvas, Button, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/custom.css';
import config from '../config';
import axios from 'axios';
import { RiAccountCircleFill } from 'react-icons/ri';
import { FaUpload } from 'react-icons/fa';

const Layout = ({ children }) => {
  const [profileData,setProfileData] = useState(null);
  const { user, logout } = React.useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [loading,setLoading] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');

  const isActive = (path) => location.pathname === path ? 'active' : '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/user/dashboard`, {
          headers: { 'x-auth-token': token }
        });
        setProfileData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data', error);
      }
    };

    fetchData();
  }, []);



  return (
    <>
      <Navbar className={` ${role === 'admin'? 'bg-dark':'navbar-custom'}`} variant="dark" expand="lg" fixed="top" style={{ height:'70px', padding: '0px' }}>
        <Container fluid>
          <div>
          <Button variant="dark" onClick={handleShow} className="me-2 d-lg-none">
            <i className="bi bi-list"></i>
          </Button>
          <a className="logo-image-panel" href="/">
            <img src="images/datahub-logo.png" alt="alternative" />
          </a>
          </div>
          <div className='d-flex align-items-center justify-space-between mr-3'>
            <div className='d-flex align-items-center'>  
            <FaUpload style={{color:'white',opacity:'0.8'}} />
          <p className='mb-0 mr-3 ml-2 text-align-center' style={{color:'white',height:'21.5px',opacity:'0.8'}}> Limit: <strong> {loading ? 'Loading..' : profileData && profileData.fileRowCount} </strong> </p>
          </div>
            <div className='d-flex align-items-center'>
            <h5  className='mb-0' style={{color:'white'}}>{loading ? 'Loading..' : profileData && profileData.name}</h5>
            <RiAccountCircleFill  style={{width:'35px',height:'35px',color:'white',marginLeft:'5px'}}/>
            </div>
            

          </div>
        </Container>
      </Navbar>
      
      <Offcanvas show={show} onHide={handleClose} className="bg-dark text-white sidebar d-lg-none">
        <Offcanvas.Header closeButton closeVariant="white">
          <Offcanvas.Title>MyApp</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link as={Link} to="/dashboard" className={`text-white ${isActive('/dashboard')}`} onClick={handleClose}>
              <i className="bi bi-house-door-fill"></i> Dashboard
            </Nav.Link>
            <Nav.Link as={Link} to="/upload-csv" className={`text-white ${isActive('/upload-csv')}`} onClick={handleClose}>
              <i className="bi bi-building-check"></i> Extract Company
            </Nav.Link>
            <Nav.Link as={Link} to="/upload-social" className={`text-white ${isActive('/upload-social')}`} onClick={handleClose}>
              <i className="bi bi-globe-asia-australia"></i> Extract Social
            </Nav.Link>
            <Nav.Link as={Link} to="/profile" className={`text-white ${isActive('/profile')}`} onClick={handleClose}>
              <i className="bi bi-person-circle"></i> Profile
            </Nav.Link>
            <Nav.Link onClick={() => { logout(); handleClose(); }} className="text-white">
              <i className="bi bi-box-arrow-right"></i> Logout
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    {role === 'user' && (<div className="sidebar d-none  d-lg-flex flex-column justify-content-between">
      
        <Nav className="flex-column">
          <Nav.Link as={Link} to="/dashboard" className={`text-white ${isActive('/dashboard')}`}>
            <i className="bi bi-house-door-fill"></i> Dashboard
          </Nav.Link>
          <Nav.Link as={Link} to="/upload-csv" className={`text-white ${isActive('/upload-csv')}`}>
            <i className="bi bi-building-check"></i> Extract Company
          </Nav.Link>
          <Nav.Link as={Link} to="/upload-social" className={`text-white ${isActive('/upload-social')}`}>
            <i className="bi bi-globe-asia-australia"></i> Extract Social
          </Nav.Link>
          <Nav.Link as={Link} to="/profile" className={`text-white ${isActive('/profile')}`}>
            <i className="bi bi-person-circle"></i> Profile
          </Nav.Link>
          <Nav.Link onClick={logout} className="text-white">
            <i className="bi bi-box-arrow-right"></i> Logout
          </Nav.Link> 
        </Nav>

        <p className='mb-0' style={{color:'white', textAlign:'center'}}>Version 1.0</p>
        
      </div>) }
      {role === "admin" && (
        <div className="sidebar d-none d-lg-block bg-dark admin-sidebar">
        <Nav className="flex-column">
          <Nav.Link as={Link} to="/admin-dashboard" className={`text-white ${isActive('/admin-dashboard')}`}>
            <i className="bi bi-house-door-fill"></i> Dashboard
          </Nav.Link>
          <Nav.Link as={Link} to="/admin-linkedin" className={`text-white ${isActive('/admin-linkedin')}`}>
            <i className="bi bi-building-check"></i> LinkedIn Data
          </Nav.Link>
          <Nav.Link as={Link} to="/admin-social" className={`text-white ${isActive('/admin-social')}`}>
            <i className="bi bi-globe-asia-australia"></i> Social Data
          </Nav.Link>
          <Nav.Link as={Link} to="/admin-users" className={`text-white ${isActive('/admin-users')}`}>
            <i className="bi bi-person-circle"></i> Users
          </Nav.Link>
          <Nav.Link onClick={logout} className="text-white">
            <i className="bi bi-box-arrow-right"></i> Logout
          </Nav.Link>
        </Nav>

        <p className='mb-0' style={{color:'white', textAlign:'center',position:'fixed',left:'60px',bottom:'3px'}}>Version 1.0</p>
      </div>
      )}
      

      <div className="content" style={{ marginTop: '56px', marginLeft: user ? '220px' : '0',padding:'5px'}}>
        {children}
        <Link className='rounded' target='_blank' to="https://emailminers.com/" style={{bottom:'3px',right:'13px',position:'fixed',textDecoration:'none',color:'black',opacity:'0.7',backgroundColor:"white"}}>Powered with &#10084; By EmailMiners</Link>
      </div>
    </>
  );
};

export default Layout;

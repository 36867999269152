import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { Alert } from 'react-bootstrap';

function Signup() {
  const [formData, setFormData] = useState({ name: '', email: '', password: '' });
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('info'); // State to manage alert variant
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.BASE_URL}/auth/signup`, formData);
      setMessage('Signup successful! Redirecting to login...');
      setVariant('success'); // Set alert variant to green for success
      setTimeout(() => {
        navigate('/login');
      }, 2000); // Wait for 2 seconds before redirecting
    } catch (error) {
      console.error('Error during signup:', error);
      if (error.response && error.response.data && error.response.data.msg) {
        setMessage(`Error during signup: ${error.response.data.msg}`);
      } else {
        setMessage('Error during signup');
      }
      setVariant('danger'); // Set alert variant to red for errors
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <>
      {/* Header */}
      <header id="header" className="ex-2-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Sign Up</h1>
              <p>
                Fill out the form below to sign up for Tivo. Already signed up? Then
                just <Link className="white" to="/login">Log In</Link>
              </p>
              {/* Sign Up Form */}
              <div className="form-container">
                <form id="signUpForm" data-toggle="validator" data-focus="false" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control-input"
                      id="semail"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <label className="label-control" htmlFor="semail">
                      Email
                    </label>
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-input"
                      id="sname"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                    <label className="label-control" htmlFor="sname">
                      Name
                    </label>
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control-input"
                      id="spassword"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    <label className="label-control" htmlFor="spassword">
                      Password
                    </label>
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group checkbox">
                    <input
                      type="checkbox"
                      id="sterms"
                      defaultValue="Agreed-to-Terms"
                      required
                    />
                    I agree with Tivo's <Link to="/privacy-policy">Privacy Policy</Link> and <Link to="/terms-conditions">Terms & Conditions</Link>
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="form-control-submit-button">
                      SIGN UP
                    </button>
                  </div>
                  <div className="form-message">
                    {message && <Alert variant={variant} className="mt-3">{message}</Alert>}
                  </div>
                </form>
              </div>
              {/* end of form container */}
              {/* end of sign up form */}
            </div>
            {/* end of col */}
          </div>
          {/* end of row */}
        </div>
        {/* end of container */}
      </header>
      {/* end of ex-header */}
      {/* end of header */}
    </>
  );
}

export default Signup;

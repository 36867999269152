import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Alert } from 'react-bootstrap';

function Login() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('info'); // State to manage alert variant
  const { login } = useContext(AuthContext); // Using the login function from the context
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(formData.email, formData.password); // Using login from context
    } catch (error) {
      console.error(error);
      if (error && error.msg) {
        setMessage(error.msg);
      } else {
        setMessage('Error during login');
      }
      setVariant('danger'); // Set alert variant to red
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <>
      {/* Header */}
      <header id="header" className="ex-2-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Log In</h1>
              <p>
                You don't have a password? Then please{' '}
                <Link className="white" to="/signup">Sign Up</Link>
              </p>
              {/* Login Form */}
              <div className="form-container">
                <form id="logInForm" data-toggle="validator" data-focus="false" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control-input"
                      id="lemail"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <label className="label-control" htmlFor="lemail">
                      Email
                    </label>
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control-input"
                      id="lpassword"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    <label className="label-control" htmlFor="lpassword">
                      Password
                    </label>
                    <div className="help-block with-errors" />
                  </div>

                  <div className="form-group">
                    <Link className="blue" to="/forgot-password">Forgot password?</Link>
                  </div>

                  <div className="form-group">
                    <button type="submit" className="form-control-submit-button">
                      LOG IN
                    </button>
                  </div>
                  <div className="form-message">
                    {message && <Alert variant={variant} className="mt-3">{message}</Alert>}
                  </div>
                </form>
              </div>
              {/* end of form container */}
            </div>
            {/* end of col */}
          </div>
          {/* end of row */}
        </div>
        {/* end of container */}
      </header>
      {/* end of ex-header */}
      {/* end of header */}
    </>
  );
}

export default Login;

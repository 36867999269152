import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        setUser({ ...decodedToken.user, role: decodedToken.role });
      } catch (error) {
        console.error('Error decoding token', error);
        localStorage.removeItem('token');
        navigate('/login');
      }
    }
  }, [navigate]);

  const login = async (email, password) => {
    try {
      const res = await axios.post(`${config.BASE_URL}/auth/login`, { email, password });
      const { token, role, status } = res.data;
      localStorage.setItem('token', token);
      localStorage.setItem('role',role);
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUser({ ...decodedToken.user, role });
      console.log(role);
      if(status !== 'active'){
        navigate('/')
      } else if (role === 'admin') {
        navigate('/admin-dashboard');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error during login', error);
      // Instead of throwing a generic error, throw the actual error response
      throw error.response ? error.response.data : new Error('Invalid credentials');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    setUser(null);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Home from './pages/Home';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import Profile from './components/Profile/Profile';
import Dashboard from './components/Dashboard/Dashboard';
import UploadCSV from './components/Upload/UploadCSV';
import UploadSocila from './components/Upload/UploadSocial'; 
import HomeLayout from './components/HomeLayout';
import PrivateRouteWithLayout from './components/PrivateRouteWithLayout';
import AdminDashboard from './components/Dashboard/AdminDashboard';
import AdminSocial from './components/Admin/AdminSocial';
import AdminLinkedin from './components/Admin/AdminLinkedin';
import AdminUsers from './components/Admin/AdminUsers';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<PrivateRouteWithLayout />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin-dashboard" element={<AdminDashboard/>} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/upload-csv" element={<UploadCSV />} />
          <Route path="/upload-social" element={<UploadSocila />} />
          <Route path="/admin-social" element={<AdminSocial/>} />
          <Route path="/admin-linkedin" element={<AdminLinkedin/>} />
          <Route path="/admin-users" element={<AdminUsers/>} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Form,
  Button,
  Alert,
  Row,
  Col,
  ProgressBar,
  Card,
  InputGroup,
  FormControl,
  Modal,
  Spinner,
  Table
} from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';
import { FaTrash, FaEye, FaCheck, FaTimes, FaUpload, FaDownload, FaSearch } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import InfiniteScroll from 'react-infinite-scroll-component';

function UploadCSV() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [message, setMessage] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('All URLs');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const token = localStorage.getItem('token');
  const fileInputRef = useRef(null);
  const [page, setPage] = useState(1);
  const [TotalRow, setTotalRow] = useState(0);
  const [FileID, setFileId] = useState();
  const [downloadCSVData,setDownloadCSVData] = useState([]);
  const [downloadData,setDownloadData] = useState([]);
  const [rowCount,setRowCount] = useState({
    compiledRow:0,
    Success_URLs:0,
    Error_URLs:0
  });
  const [disable, setDisable] = useState(false);
  const [pending,setPending] = useState(true);

  useEffect(() => {
    fetchUploadedFiles();
    const interval = setInterval(fetchUploadedFiles, 5000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    setFilteredFiles(
      uploadedFiles.filter(file =>
        file.fileName.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, uploadedFiles]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const fetchUploadedFiles = async () => {
    //setLoading(true);
    try {
      const res = await axios.get(`${config.BASE_URL}/user/file-list-linkdin`, {
        headers: { 'x-auth-token': token }
      });
      console.log('Fetched Files:', res.data); // Log fetched files
      setUploadedFiles(res.data);
      setPending(false);
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
      if (error.response && error.response.status === 401) {
        // Redirect to login page if the session has expired
        window.location.href = '/login';
      }
    }
   // setLoading(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && (selectedFile.type === 'text/csv' || selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setMessage('Invalid file type. Please upload a CSV or Excel file.');
    }
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Please select a valid file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('fileDataType', 'linkedin');

    try {
      setLoading(true);
      const res = await axios.post(`${config.BASE_URL}/user/upload-csv`, formData, {
        headers: { 'x-auth-token': token, 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
      });
      setMessage(`File uploaded successfully! Valid URLs: ${res.data.validUrls}, Invalid URLs: ${res.data.invalidUrls}`);
      setFile(null); // Clear file input
      setFileName(''); // Clear file name input
      fileInputRef.current.value = ''; // Clear file input text
      fetchUploadedFiles();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage(error.response.data.error);
      } else {
        setMessage('Error uploading file');
      }
    }

    setLoading(false);
    setUploadProgress(0);
  };

  const handleView = async (fileId,compileRow,Success_URLs,Error_URLs) => {
    setRowCount({
      compiledRow:compileRow,
      Success_URLs:Success_URLs,
      Error_URLs:Error_URLs
    });
    setFileId(fileId);
    setModalLoading(true);
    setModalShow(true);
    setPage(1);
    try {
      const res = await axios.get(`${config.BASE_URL}/user/linkdindata/${fileId}?page=1&limit=100`, {
        headers: { 'x-auth-token': token }
      });
      setModalData(res.data);
    } catch (error) {
      console.error('Error fetching file data:', error);
    }
    setModalLoading(false);
  };

  const fetchMore = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/user/linkdindata/${FileID}?page=${page + 1}&limit=100`, { headers: { 'x-auth-token': token } });
      setPage(page + 1);
      setModalData(modalData.concat(res.data));
    }
    catch (error) {
      console.error('Error fetching file data:', error);
    }
  };

  const handleDelete = (fileId) => {
    setFileToDelete(fileId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.put(
        `${config.BASE_URL}/user/delete-file/${fileToDelete}`,
        { status: 'Deleted' },
        { headers: { 'x-auth-token': token } }
      );
      setMessage('File marked as deleted.');
      fetchUploadedFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
      setMessage('Error deleting file.');
    }
    setShowDeleteModal(false);
    setFileToDelete(null);
  };


  useEffect(() => {
    if (downloadCSVData.length !== 0) {
      const visibleData = filterDownloadCSVData().map(({ URL, Status, AboutUs, AddressRegion, AddressLocality, StreetAddress, AddressCountry, CompanyURL, Industry, CompanySize, createdAt }) => ({
        URL,
        Status,
        'About Us': AboutUs,
        Region: AddressRegion,
        Locality: AddressLocality,
        Street: StreetAddress,
        Country: AddressCountry,
        'Company URL': CompanyURL,
        Industry,
        Size: CompanySize,
        createdAt
      }));
      const csv = Papa.unparse(visibleData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `${downloadCSVData[0]?.fileName || 'data'}.csv`);
      setDisable(false);
    }
  }, [downloadCSVData])

  useEffect(() => {
    if (downloadData.length !== 0) {
      const visibleData = filterDownloadData().map(({ URL, Status, AboutUs, AddressRegion, AddressLocality, StreetAddress, AddressCountry, CompanyURL, Industry, CompanySize, createdAt }) => ({
        URL,
        Status,
        'About Us': AboutUs,
        Region: AddressRegion,
        Locality: AddressLocality,
        Street: StreetAddress,
        Country: AddressCountry,
        'Company URL': CompanyURL,
        Industry,
        Size: CompanySize,
        createdAt
      }));
      try {
        const ws = XLSX.utils.json_to_sheet(visibleData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${downloadData[0]?.fileName || 'data'}.xlsx`);
      } catch (error) {
        console.error('Error generating Excel file:', error);
      }
      setDisable(false);
    }
  }, [downloadData])


  const handleDownloadCSV = async () => {
    setDisable(true);
    
    const res = await axios.get(`${config.BASE_URL}/user/linkdindata/${FileID}`, {
      headers: { 'x-auth-token': token }
    });
     setDownloadCSVData(res.data);
  //   if (downloadData.length !== 0) {
  //   const visibleData = filterDownloadData().map(({ URL, Status, AboutUs, AddressRegion, AddressLocality, StreetAddress, AddressCountry, CompanyURL, Industry, CompanySize, createdAt }) => ({
  //     URL,
  //     Status,
  //     'About Us': AboutUs,
  //     Region: AddressRegion,
  //     Locality: AddressLocality,
  //     Street: StreetAddress,
  //     Country: AddressCountry,
  //     'Company URL': CompanyURL,
  //     Industry,
  //     Size: CompanySize,
  //     createdAt
  //   }));
  //   const csv = Papa.unparse(visibleData);
  //   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //   saveAs(blob, `${downloadData[0]?.fileName || 'data'}.csv`);
  // }

  // setDisable(false);
  };

  const handleDownloadExcel = async () => {
    setDisable(true);
    const res = await axios.get(`${config.BASE_URL}/user/linkdindata/${FileID}`, {
      headers: { 'x-auth-token': token }
    });
    setDownloadData(res.data);
  //   if (downloadData.length !== 0) {
  //   const visibleData = filterDownloadData().map(({ URL, Status, AboutUs, AddressRegion, AddressLocality, StreetAddress, AddressCountry, CompanyURL, Industry, CompanySize, createdAt }) => ({
  //     URL,
  //     Status,
  //     'About Us': AboutUs,
  //     Region: AddressRegion,
  //     Locality: AddressLocality,
  //     Street: StreetAddress,
  //     Country: AddressCountry,
  //     'Company URL': CompanyURL,
  //     Industry,
  //     Size: CompanySize,
  //     createdAt
  //   }));
  //   try {
  //     const ws = XLSX.utils.json_to_sheet(visibleData);
  //     const wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //     XLSX.writeFile(wb, `${downloadData[0]?.fileName || 'data'}.xlsx`);
  //   } catch (error) {
  //     console.error('Error generating Excel file:', error);
  //   }
  // }

  // setDisable(false);
  };

  const filterModalData = () => {
    if (statusFilter === 'All URLs') {
      return modalData;
    }
    if (statusFilter === 'Success URLs') {
      return modalData.filter(item => item.Status === 'Success');
    }
    if (statusFilter === 'Error URLs') {
      return modalData.filter(item => item.Status === 'Error');
    }
    return modalData;
  };

  const filterDownloadData = () => {
    if (statusFilter === 'All URLs') {
      return downloadData;
    }
    if (statusFilter === 'Success URLs') {
      return downloadData.filter(item => item.Status === 'Success');
    }
    if (statusFilter === 'Error URLs') {
      return downloadData.filter(item => item.Status === 'Error');
    }
    return downloadData;
  };

  const filterDownloadCSVData = () => {
    if (statusFilter === 'All URLs') {
      return downloadCSVData;
    }
    if (statusFilter === 'Success URLs') {
      return downloadCSVData.filter(item => item.Status === 'Success');
    }
    if (statusFilter === 'Error URLs') {
      return downloadCSVData.filter(item => item.Status === 'Error');
    }
    return downloadCSVData;
  };

  const columns = [
    { name: 'File Name', selector: row => row.fileName, sortable: true, cell: row => (
      <span>
        <i className="bi bi-file-earmark-spreadsheet bi-4x"></i> {row.fileName}
      </span>
    ) },
    { name: 'Status', selector: row => row.status, sortable: true },
    { name: 'Total Rows', selector: row => row.totalRow, sortable: true },
    { name: 'Compile Rows', selector: row => row.compileRow, sortable: true },
    { name: 'Success' , selector: row => row.Success_URLs, sortable: true},
    { name: 'Errors' , selector: row => row.Error_URLs, sortable: true},
    { name: 'Date Time', selector: row => new Date(row.datetime).toLocaleString(), sortable: true },
    {
      name: 'Actions',
      cell: row => {
        const totalRow = Number(row.totalRow);
        const compileRow = Number(row.compileRow);
        const isComplete = totalRow === compileRow;
        const progress = (compileRow / totalRow) * 100;
        const progressBarLabel = compileRow === 0 ? 'Progress' : `${progress.toFixed(2)}%`;

        return (
          <div>
            {row.status === 'Completed' ? (
              <>
                <Button variant="link" onClick={() => handleView(row._id,row.compileRow,row.Success_URLs,row.Error_URLs)}><FaEye /></Button>
                <Button variant="link" onClick={() => handleDelete(row._id)}><FaTrash /></Button>
              </>
            ) : (
              <ProgressBar now={progress} label={progressBarLabel} style={{ minWidth: '100px' }} />
            )}
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <Container style={{ marginTop: '20px' }}>
      <Card className="p-3" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <h2>Extract Company Details from LinkedIn URLs</h2>
        <p>Upload your company list in CSV or Excel format, and let our tool automatically extract detailed company profiles from LinkedIn URLs. Simply ensure the first row of your file contains the LinkedIn company URLs, and our advanced extractor will handle the rest, providing you with comprehensive company insights in no time.</p>
        <hr></hr>

        <Form onSubmit={handleSubmit}>
          <Row className="align-items-center">
            <Col md={4}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload File</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} ref={fileInputRef} required />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formFileName" className="mb-3">
                <Form.Label>File Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder='File name'
                  value={fileName}
                  onChange={handleFileNameChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Button type="submit" variant="primary" className="mt-3 btn-solid-lg"><FaUpload /> Upload</Button>
            </Col>
          </Row>
        </Form>
        <div style={{ height: '50px' }}>
        {loading && <ProgressBar now={uploadProgress} className="mt-3" />}
        {message && <Alert variant="info" className="mt-3">{message}</Alert>}
        </div>
      </Card>
      <Card className="mt-4 p-3" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <InputGroup className="mb-3">
          <InputGroup.Text>
            <FaSearch />
          </InputGroup.Text>
          <FormControl
            placeholder="Search by file name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </InputGroup>
        <DataTable
          columns={columns.map(column => ({ ...column, button: undefined, allowOverflow: undefined }))}
          data={filteredFiles}
          pagination
          highlightOnHover
          pointerOnHover
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 25, 50]}
          paginationComponentOptions={{ rowsPerPageText: 'Entries per page:', rangeSeparatorText: 'of' }}
          progressPending={pending}
          progressComponent={<Spinner animation="border" variant="secondary" />}
        />
      </Card>


      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            File Data
            <span style={{ marginLeft: '20px', fontSize: '14px', color: 'gray' }}>
              {`Row Count: ${statusFilter === 'Success URLs'? rowCount.Success_URLs : statusFilter === 'Error URLs' ? rowCount.Error_URLs : rowCount.compiledRow }`}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalLoading ? (
            <Spinner animation="border" />
          ) : (
            <>
             <div style={{ padding: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
  <Form.Group className='d-flex justify-content-between align-items-center'>
    <div>
    <div style={{ display: 'inline-block', marginRight: '10px' }} onClick={() => document.getElementById('success-urls').click()}>
      <Form.Check
        inline
        type="radio"
        label="Success URLs"
        name="statusFilter"
        id="success-urls"
        onChange={() => setStatusFilter('Success URLs')}
      />
    </div>
    <div style={{ display: 'inline-block', marginRight: '10px' }} onClick={() => document.getElementById('error-urls').click()}>
      <Form.Check
        inline
        type="radio"
        label="Error URLs"
        name="statusFilter"
        id="error-urls"
        onChange={() => setStatusFilter('Error URLs')}
      />
    </div>
    <div style={{ display: 'inline-block', marginRight: '10px' }} onClick={() => document.getElementById('all-urls').click()}>
      <Form.Check
        inline
        type="radio"
        label="All URLs"
        name="statusFilter"
        id="all-urls"
        defaultChecked
        onChange={() => setStatusFilter('All URLs')}
      />
    </div>
    </div>
    <div>
    <Button variant="primary" onClick={handleDownloadCSV} disabled={disable} className="ml-2 btn-solid-lg"><FaDownload />CSV</Button>
    <Button variant="primary" onClick={handleDownloadExcel} disabled={disable} className="ml-2 btn-solid-lg"><FaDownload />Excel</Button>
    </div>
  </Form.Group>
</div>

              <div id='tableWrapper' className="table-wrapper">
              <InfiniteScroll
                  dataLength={filterModalData().length}
                  next={fetchMore}
                  hasMore={filterModalData().length < rowCount.compiledRow}
                  loader={statusFilter === 'Error URLs' || statusFilter === 'Success URLs' ? " " : <h6>Loading...</h6>}
                  scrollableTarget="tableWrapper"
                  style={{ overflow: "unset" }}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  scrollThreshold={1}
                >

                <Table striped bordered hover>
                  <thead className="sticky-header">
                  <tr>
                  <th><span className="d-inline-flex align-items-center"> SNo.</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-link-45deg me-2"></i> URL</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-info-circle me-2"></i> Status</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-info-square me-2"></i> About Us</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-geo-alt me-2"></i> Region</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-geo me-2"></i> Locality</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-signpost-split me-2"></i> Street</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-flag me-2"></i> Country</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-link-45deg me-2"></i> Company URL</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-building me-2"></i> Industry</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-arrows-angle-expand me-2"></i> Size</span></th>
  <th><span className="d-inline-flex align-items-center"><i className="bi bi-calendar me-2"></i> createdAt</span></th>
</tr>

                  </thead>
                  <tbody>
                    {filterModalData().map((item,index )=> (
                      <tr className='Sheet-data' key={item._id}>
                        <td>{index+1}</td>
                        <td>{item.URL}</td>
                        <td>{item.Status}</td>
                        <td>{item.AboutUs}</td>
                        <td>{item.AddressRegion}</td>
                        <td>{item.AddressLocality}</td>
                        <td>{item.StreetAddress}</td>
                        <td>{item.AddressCountry}</td>
                        <td>{item.CompanyURL}</td>
                        <td>{item.Industry}</td>
                        <td>{item.CompanySize}</td>
                        <td>{new Date(item.createdAt).toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                </InfiniteScroll>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title><FaTrash /> Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this file?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default UploadCSV;

import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${config.BASE_URL}/auth/forgot-password`, { email });
      setMessage('OTP sent to email!');
      setTimeout(() => {
        navigate('/reset-password');
      }, 2000); // Wait for 2 seconds before redirecting
    } catch (error) {
      setMessage('Error sending OTP');
    }
  };

  // return (
  //   <Container className="mt-5">
  //     <h1 className="text-center">Forgot Password</h1>
  //     <Form onSubmit={handleSubmit}>
  //       <Form.Group controlId="formEmail">
  //         <Form.Label>Email</Form.Label>
  //         <Form.Control
  //           type="email"
  //           value={email}
  //           onChange={(e) => setEmail(e.target.value)}
  //           required
  //         />
  //       </Form.Group>
  //       <Button type="submit" variant="primary" className="mt-3">Send OTP</Button>
  //     </Form>
  //     {message && <Alert variant="info" className="mt-3">{message}</Alert>}
  //   </Container>
  // );


  return (
    <>
      {/* Header */}
      <header id="header" className="ex-2-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Forgot Password</h1>
              <p className="mt-3">Remember your password? <Link to="/login">Login</Link></p>
              {/* Login Form */}
              <div className="form-container">
                <form id="logInForm" data-toggle="validator" data-focus="false" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control-input"
                      id="lemail"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <label className="label-control" htmlFor="lemail">
                      Email
                    </label>
                    <div className="help-block with-errors" />
                  </div>
                  

                 
                  
                  <div className="form-group">
                    <button type="submit" className="form-control-submit-button">
                    Send OTP
                    </button>
                  </div>
                  <div className="form-message">
                  {message && <Alert variant="info" className="mt-3">{message}</Alert>}
                  </div>
                </form>
              </div>
              {/* end of form container */}
            </div>
            {/* end of col */}
          </div>
          {/* end of row */}
        </div>
        {/* end of container */}
      </header>
      {/* end of ex-header */}
      {/* end of header */}
    </>
  );



}

export default ForgotPassword;

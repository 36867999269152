import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import config from '../../config';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaFileAlt, FaLink, FaCalendarDay, FaChartLine } from 'react-icons/fa';



const cardStyle = {
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

const iconStyle = (color) => ({
  fontSize: '30px',
  color: color,
  marginBottom: '10px'
});

const textStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '5px'
};

const subTextStyle = {
  fontSize: '16px',
  color: 'gray'
};





ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({
    totalFiles: 0,
    processingLinks: 0,
    weeklyFileData: [],
    weeklyLinkData: [],
    filesToday: 0,
    linksToday: 0
  });
  const token = localStorage.getItem('token');
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/user/dashboard`, {
          headers: { 'x-auth-token': token }
        });
        setDashboardData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data', error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        }
      }
    };

    fetchData();
  }, [token]);

  const fileChartData = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
      {
        label: 'Files Processed per Day',
        data: dashboardData.weeklyFileData.map(data => data.count),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const linkChartData = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
      {
        label: 'Links Processed per Day',
        data: dashboardData.weeklyLinkData.map(data => data.count),
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container fluid>
      <h1 className="text-left my-4 ml-3" style={{ fontSize: '2rem', display: 'flex', alignItems: 'center' }}>
        <FaChartLine style={{ marginRight: '10px' }} /> Dashboard
      </h1>


     
      <Row className="mb-4">
      <Col md={3}>
        <Card style={cardStyle}>
          <FaFileAlt style={iconStyle('red')} />
          <div style={textStyle}>{loading ? <Spinner animation="border" variant="secondary"/> : dashboardData.totalFiles}</div>
          <div style={subTextStyle}>Total Files</div>
        </Card>
      </Col>
      <Col md={3}>
        <Card style={cardStyle}>
          <FaLink style={iconStyle('blue')} />
          <div style={textStyle}>{loading ? <Spinner animation="border" variant="secondary"/> : dashboardData.processingLinks}</div>
          <div style={subTextStyle}>Processing Links</div>
        </Card>
      </Col>
      <Col md={3}>
        <Card style={cardStyle}>
          <FaCalendarDay style={iconStyle('green')} />
          <div style={textStyle}>{loading ? <Spinner animation="border" variant="secondary"/> : dashboardData.filesToday}</div>
          <div style={subTextStyle}>Files Today</div>
        </Card>
      </Col>
      <Col md={3}>
        <Card style={cardStyle}>
          <FaCalendarDay style={iconStyle('purple')} />
          <div style={textStyle}>{loading ? <Spinner animation="border" variant="secondary"/> : dashboardData.linksToday}</div>
          <div style={subTextStyle}>Processing Today</div>
        </Card>
      </Col>
    </Row>




      <Card className="mb-4">
        <Card.Body>
          <Card.Title><FaChartLine style={{ marginRight: '10px' }} /> Files Processed Data</Card.Title>
          <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
            <Line data={fileChartData} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body>
          <Card.Title><FaChartLine style={{ marginRight: '10px' }} /> Links Processed Data</Card.Title>
          <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
            <Line data={linkChartData} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Dashboard;

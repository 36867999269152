import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import config from "../../config";
import DataTable from "react-data-table-component";
import { FaEye, FaPenNib, FaSearch, FaTrash } from "react-icons/fa";

const AdminUsers = () => {
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);

  const fetchUserData = async () => {
    const res = await axios.get(`${config.BASE_URL}/admin/users`, {
      headers: { "x-auth-token": token },
    });
    console.log(res.data);
    setUserData(res.data);
  };

  const handleClick = (id, fileRowCount, status, name) => {
    const details = {
      id: id,
      fileRowCount: fileRowCount,
      status: status,
      name: name,
    };
    setModalData(details);
    setShow(true);
    console.log("Data", modalData);
  };
  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setModalData((prevDetails) => ({
      ...prevDetails,
      status: newStatus,
    }));
  };

  const handleFileRowCountChange = (e) => {
    const newFileRowCount = e.target.value;
    setModalData((prevDetails) => ({
      ...prevDetails,
      fileRowCount: newFileRowCount,
    }));
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async () => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/admin/users/${modalData.id}`,
        { status: modalData.status, fileRowCount: modalData.fileRowCount },
        { headers: { "x-auth-token": token } }
      );
    } catch (error) {
      console.log(error);
    }
    handleClose();
    fetchUserData();
  };

  useEffect(() => {
    console.log("token:", token);
    fetchUserData();
  }, []);

  useEffect(() => {
    setFilteredFiles(
      userData.filter((file) =>
        file.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, userData]);

  const columns = [
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Role", selector: (row) => row.role, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
    {
      name: "File Row Count",
      selector: (row) => row.fileRowCount,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div>
            <>
              <Button
                variant="link"
                onClick={() =>
                  handleClick(row._id, row.fileRowCount, row.status, row.name)
                }
              >
                <FaPenNib style={{ color: "#030508" }} />
              </Button>
            </>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div>
      <h4 className="mb-2 mt-4 ml-3 font-weight-bold">User Details</h4>
      <div className="d-flex flex-wrap justify-content-around">
        <Card
          className="mt-1 p-3"
          style={{
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            width: "100%",
          }}
        >
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaSearch />
            </InputGroup.Text>
            <FormControl
              placeholder="Search by file name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
          <DataTable
            columns={columns.map((column) => ({
              ...column,
              button: undefined,
              allowOverflow: undefined,
            }))}
            data={filteredFiles}
            fixedHeader
            fixedHeaderScrollHeight="60vh"
            pagination
            highlightOnHover
            pointerOnHover
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Entries per page:",
              rangeSeparatorText: "of",
            }}
          />
        </Card>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Update {modalData && modalData.name}
              {`'s`} details{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="statusSelect">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={modalData && modalData.status}
                  onChange={handleStatusChange}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="fileRowCountInput">
                <Form.Label>File Row Count</Form.Label>
                <Form.Control
                  type="number"
                  value={modalData && modalData.fileRowCount}
                  onChange={handleFileRowCountChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminUsers;

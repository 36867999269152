
import { AuthContext } from '../../context/AuthContext';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import config from '../../config';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaUsers, FaLink, FaCalendarDay, FaChartLine, FaFileSignature } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GrUserNew } from 'react-icons/gr';
import { TbSocial } from 'react-icons/tb';
import { TiSocialLinkedin } from 'react-icons/ti';
import { VscServerProcess } from 'react-icons/vsc';
import { BiCheckDouble } from 'react-icons/bi';
import { RiAdminLine } from 'react-icons/ri';



const cardStyle = {
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '15px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '1rem'
  // backgroundColor: 'rgb(107 107 107)',
  // color: 'white'
};

const iconStyle = (color) => ({
  fontSize: '30px',
  color: color,
  marginBottom: '10px'
});

const textStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '5px'
};

const subTextStyle = {
  fontSize: '16px',
  color: 'grey'
};





ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AdminDashboard() {
  // const { user, logout } = React.useContext(AuthContext);

  const [dashboardData, setDashboardData] = useState({
    totalUsers: 0,
    newUsersToday: 0,
    totalPending: 0,
    totalCompleted: 0,
    socialDataCount: 0,
    linkedinDataCount: 0,
    newUsersGraphData:[],
    fileProcessingGraphData:[],
    mergedDataProcessingGraphData:[]
  });

  const token = localStorage.getItem('token');
  const role = 'admin'; //localStorage.getItem('role');
  const navigate = useNavigate();

  useEffect(() => {
    console.log(token);
    if(role === 'user'){
      navigate("/dashboard");
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/admin/dashboard`, {
          headers: { 'x-auth-token': token }
        });
        setDashboardData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard data', error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        }
      }
    };
    fetchData();
  }, [token]);

  const userChartData = {
    labels: dashboardData.newUsersGraphData.map(data => data._id),
    datasets: [
      {
        label: 'Users registered per Day',
        data: dashboardData.newUsersGraphData.map(data => data.count),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const linkChartData = {
    labels: dashboardData.fileProcessingGraphData.map(data => data._id),
    datasets: [
      {
        label: 'Files Processed per Day',
        data: dashboardData.fileProcessingGraphData.map(data => data.count),
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
      {
        label: 'Data Processed per Day',
        data: dashboardData.mergedDataProcessingGraphData.map(data => data.count),
        backgroundColor: 'yellow',
        borderColor: 'orange',
        borderWidth: 1,
      },
    ],
  };

  return (


    <Container fluid>
    <h1 className="text-left my-4 ml-4" style={{ fontSize: '2rem', display: 'flex', alignItems: 'center' }}>
      <RiAdminLine style={{ marginRight: '10px' }} /> Admin Dashboard
    </h1>


   
    <Row className="mb-4 d-flex justify-content-center">
    <Col md={2}>
      <Card style={cardStyle}>
        <FaUsers style={iconStyle('violet')} />
        <div style={textStyle}>{dashboardData.totalUsers}</div>
        <div style={subTextStyle}>Total Users</div>
      </Card>
    </Col>
    <Col md={2}>
      <Card style={cardStyle}>
        <GrUserNew style={iconStyle('orange')} />
        <div style={textStyle}>{dashboardData.newUsersToday}</div>
        <div style={subTextStyle}>New Users Today</div>
      </Card>
    </Col>
    <Col md={2}>
      <Card style={cardStyle}>
        <TbSocial style={iconStyle('lightgreen')} />
        <div style={textStyle}>{dashboardData.socialDataCount}</div>
        <div style={subTextStyle}>Social Data</div>
      </Card>
    </Col>
    <Col md={2}>
      <Card style={cardStyle}>
        <TiSocialLinkedin style={iconStyle('#3a9ff9')} />
        <div style={textStyle}>{dashboardData.linkedinDataCount}</div>
        <div style={subTextStyle}>LinkedIn Data</div>
      </Card>
    </Col>
    <Col md={2}>
      <Card style={cardStyle}>
        <VscServerProcess style={iconStyle('red')} />
        <div style={textStyle}>{dashboardData.totalPending}</div>
        <div style={subTextStyle}>Pending Files</div>
      </Card>
    </Col>
    <Col md={2}>
      <Card style={cardStyle}>
        <BiCheckDouble style={iconStyle('purple')} />
        <div style={textStyle}>{dashboardData.totalCompleted}</div>
        <div style={subTextStyle}>Processed Files</div>
      </Card>
    </Col>
  </Row>




    <Card className="mb-4">
      <Card.Body>
        <Card.Title><FaUsers style={{ marginRight: '10px' }} /> Total Users Registered per Day </Card.Title>
        <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
          <Line data={userChartData} options={{ responsive: true, maintainAspectRatio: false }} />
        </div>
      </Card.Body>
    </Card>
    <Card className="mb-4">
      <Card.Body>
        <Card.Title><FaFileSignature style={{ marginRight: '10px' }} /> Files Processed with data count</Card.Title>
        <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
          <Line data={linkChartData} options={{ responsive: true, maintainAspectRatio: false }} />
        </div>
      </Card.Body>
    </Card>
  </Container>
  );
}

export default AdminDashboard;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swiper from "swiper";

function Home() {
  const navigate = useNavigate();

  const handleNavigation = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  useEffect(() => {
    new Swiper(".swiper-container", {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, []);

  return (
    <>
      {/* Header */}
      <header id="header" className="header">
        <div className="header-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-xl-5">
                <div className="text-container">
                  <h1>
                    Extract Social Media and Contact Information Instantly!
                  </h1>
                  <p className="p-large">
                    Unlock the power of data extraction with our free tool.
                    Extract emails, phone numbers, social media links, and more
                    from any website or LinkedIn company page in seconds.
                  </p>

                  <Link className="btn-solid-lg page-scroll" to="/signup">
                    SIGN UP
                  </Link>

                  {/* <a className="btn-solid-lg page-scroll" href="/signup">
                SIGN UP
              </a> */}
                </div>{" "}
                {/* end of text-container */}
              </div>{" "}
              {/* end of col */}
              <div className="col-lg-6 col-xl-7">
                <div className="image-container">
                  <div className="img-wrapper">
                    <img
                      className="img-fluid"
                      src="images/header-software-app.png"
                      alt="alternative"
                    />
                  </div>{" "}
                  {/* end of img-wrapper */}
                </div>{" "}
                {/* end of image-container */}
              </div>{" "}
              {/* end of col */}
            </div>{" "}
            {/* end of row */}
          </div>{" "}
          {/* end of container */}
        </div>{" "}
        {/* end of header-content */}
      </header>{" "}
      {/* end of header */}
      <svg
        className="header-frame"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox="0 0 1920 310"
      >
        <defs>
          <linearGradient id="gradient" x1="-10%" y1="0%" x2="100%" y2="100%">
            <stop offset="-50%" style={{ stopColor: "rgba(65, 35, 153, 1)" }} />
            <stop offset="40%" style={{ stopColor: "rgba(153, 25, 132, 1)" }} />
          </linearGradient>
          <style
            dangerouslySetInnerHTML={{
              __html: ".cls-1{fill: url(#gradient);} ",
            }}
          />
        </defs>
        <title>header-frame</title>
        <path
          className="cls-1"
          d="M0,283.054c22.75,12.98,53.1,15.2,70.635,14.808,92.115-2.077,238.3-79.9,354.895-79.938,59.97-.019,106.17,18.059,141.58,34,47.778,21.511,47.778,21.511,90,38.938,28.418,11.731,85.344,26.169,152.992,17.971,68.127-8.255,115.933-34.963,166.492-67.393,37.467-24.032,148.6-112.008,171.753-127.963,27.951-19.26,87.771-81.155,180.71-89.341,72.016-6.343,105.479,12.388,157.434,35.467,69.73,30.976,168.93,92.28,256.514,89.405,100.992-3.315,140.276-41.7,177-64.9V0.24H0V283.054Z"
        />
      </svg>
      {/* end of header */}
      {/* Customers */}
      {/* <div className="slider-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
          
              <div className="slider-container">
                <div className="swiper-container image-slider">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <img
                        className="img-fluid"
                        src="images/customer-logo-1.png"
                        alt="alternative"
                      />
                    </div>
                    <div className="swiper-slide">
                      <img
                        className="img-fluid"
                        src="images/customer-logo-2.png"
                        alt="alternative"
                      />
                    </div>
                    <div className="swiper-slide">
                      <img
                        className="img-fluid"
                        src="images/customer-logo-3.png"
                        alt="alternative"
                      />
                    </div>
                    <div className="swiper-slide">
                      <img
                        className="img-fluid"
                        src="images/customer-logo-4.png"
                        alt="alternative"
                      />
                    </div>
                    <div className="swiper-slide">
                      <img
                        className="img-fluid"
                        src="images/customer-logo-5.png"
                        alt="alternative"
                      />
                    </div>
                    <div className="swiper-slide">
                      <img
                        className="img-fluid"
                        src="images/customer-logo-6.png"
                        alt="alternative"
                      />
                    </div>
                  </div>{" "}
                
                </div>{" "}
              
              </div>{" "}
              
              
            </div>{" "}
        
          </div>{" "}
         
        </div>{" "}
        
      </div>{" "} */}
      {/* end of slider-1 */}
      {/* end of customers */}
      {/* Description */}
      {/* end of cards-1 */}
      {/* end of description */}
      {/* Features */}
      <div id="features" className="tabs">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="above-heading">FEATURES</div>
              <h2 className="h2-heading">
                Powerful Features at Your Fingertips
              </h2>
              {/* <p className="p-heading">
                Take your business strategy to the next level and automatize
                your marketing tasks to save time.
              </p> */}
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
          <div className="row">
            <div className="col-lg-12">
              {/* Tabs Links */}
              <ul className="nav nav-tabs" id="argoTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="nav-tab-1"
                    data-toggle="tab"
                    href="#tab-1"
                    role="tab"
                    aria-controls="tab-1"
                    aria-selected="true"
                  >
                    <i className="fas fa-list" />
                    LinkedIn Extractor
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="nav-tab-2"
                    data-toggle="tab"
                    href="#tab-2"
                    role="tab"
                    aria-controls="tab-2"
                    aria-selected="false"
                  >
                    <i className="fas fa-envelope-open-text" />
                    Social Extractor
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    id="nav-tab-3"
                    data-toggle="tab"
                    href="#tab-3"
                    role="tab"
                    aria-controls="tab-3"
                    aria-selected="false"
                  >
                    <i className="fas fa-chart-bar" />
                    Analytics
                  </a>
                </li> */}
              </ul>
              {/* end of tabs links */}
              {/* Tabs Content */}
              <div className="tab-content" id="argoTabsContent">
                {/* Tab */}
                <div
                  className="tab-pane fade show active"
                  id="tab-1"
                  role="tabpanel"
                  aria-labelledby="tab-1"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src="images/features-1.png"
                          alt="alternative"
                        />
                      </div>{" "}
                      {/* end of image-container */}
                    </div>{" "}
                    {/* end of col */}
                    <div className="col-lg-6">
                      <div className="text-container d-flex justify-content-center h-100 flex-column m-0">
                        <h3>Extracting LinkedIn Data Is Easier Than Ever</h3>
                        <p>
                          Input a LinkedIn company URL and receive comprehensive
                          details including company size, location, industry,
                          and more.
                        </p>
                        {/* <ul className="list-unstyled li-space-lg">
                          <li className="media">
                            <i className="fas fa-square" />
                            <div className="media-body">
                              Create and embed on websites newsletter sign up
                              forms
                            </div>
                          </li>
                          <li className="media">
                            <i className="fas fa-square" />
                            <div className="media-body">
                              Manage forms and landing pages for your services
                            </div>
                          </li>
                          <li className="media">
                            <i className="fas fa-square" />
                            <div className="media-body">
                              Add and remove subscribers using the control panel
                            </div>
                          </li>
                        </ul>
                        <a
                          className="btn-solid-reg popup-with-move-anim"
                          href="#details-lightbox-1"
                        >
                          LIGHTBOX
                        </a> */}
                      </div>{" "}
                      {/* end of text-container */}
                    </div>{" "}
                    {/* end of col */}
                  </div>{" "}
                  {/* end of row */}
                </div>{" "}
                {/* end of tab-pane */}
                {/* end of tab */}
                {/* Tab */}
                <div
                  className="tab-pane fade"
                  id="tab-2"
                  role="tabpanel"
                  aria-labelledby="tab-2"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src="images/features-2.png"
                          alt="alternative"
                        />
                      </div>{" "}
                      {/* end of image-container */}
                    </div>{" "}
                    {/* end of col */}
                    <div className="col-lg-6">
                      <div className="text-container h-100 d-flex justify-content-center flex-column m-0">
                        <h3>
                          Extracting Social Data From the Website is Now Easier{" "}
                        </h3>
                        <p>
                          Enter a website URL and get all social media links,
                          phone numbers, and emails available on the site
                        </p>
                        {/* <ul className="list-unstyled li-space-lg">
                          <li className="media">
                            <i className="fas fa-square" />
                            <div className="media-body">
                              Easily plan campaigns and schedule their starting
                              date
                            </div>
                          </li>
                          <li className="media">
                            <i className="fas fa-square" />
                            <div className="media-body">
                              Start campaigns and follow their evolution closely
                            </div>
                          </li>
                          <li className="media">
                            <i className="fas fa-square" />
                            <div className="media-body">
                              Evaluate campaign results and optimize future
                              actions
                            </div>
                          </li>
                        </ul>
                        <a
                          className="btn-solid-reg popup-with-move-anim"
                          href="#details-lightbox-2"
                        >
                          LIGHTBOX
                        </a> */}
                      </div>{" "}
                      {/* end of text-container */}
                    </div>{" "}
                    {/* end of col */}
                  </div>{" "}
                  {/* end of row */}
                </div>{" "}
                {/* end of tab-pane */}
                {/* end of tab */}
                {/* Tab */}
                <div
                  className="tab-pane fade"
                  id="tab-3"
                  role="tabpanel"
                  aria-labelledby="tab-3"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src="images/features-3.png"
                          alt="alternative"
                        />
                      </div>{" "}
                      {/* end of image-container */}
                    </div>{" "}
                    {/* end of col */}
                    <div className="col-lg-6">
                      <div className="text-container">
                        <h3>Analytics Control Panel</h3>
                        <p>
                          Analytics control panel is important for every
                          marketing team so it's beed implemented from the
                          begging and designed to produce reports based on very
                          little input information.
                        </p>
                        <ul className="list-unstyled li-space-lg">
                          <li className="media">
                            <i className="fas fa-square" />
                            <div className="media-body">
                              If you set it up correctly you will get acces to
                              great intel
                            </div>
                          </li>
                          <li className="media">
                            <i className="fas fa-square" />
                            <div className="media-body">
                              Easy to integrate in your websites and landing
                              pages
                            </div>
                          </li>
                          <li className="media">
                            <i className="fas fa-square" />
                            <div className="media-body">
                              The generated reports are important for your
                              strategy
                            </div>
                          </li>
                        </ul>
                        <a
                          className="btn-solid-reg popup-with-move-anim"
                          href="#details-lightbox-3"
                        >
                          LIGHTBOX
                        </a>
                      </div>{" "}
                      {/* end of text-container */}
                    </div>{" "}
                    {/* end of col */}
                  </div>{" "}
                  {/* end of row */}
                </div>{" "}
                {/* end of tab-pane */}
                {/* end of tab */}
              </div>{" "}
              {/* end of tab content */}
              {/* end of tabs content */}
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
      {/* end of tabs */}
      {/* end of features */}
      {/* Details */}
      <div id="details" className="basic-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container h-100 d-flex justify-content-center flex-column m-0">
                <h2>How It Works</h2>
                {/* <p>
                  Target the right customers for your business with the help of
                  Tivo's patented segmentation technology and deploy efficient
                  marketing campaigns. Keep your customers happy and loyal.
                </p> */}
                <ul className="list-unstyled li-space-lg">
                  <li className="media">
                    <i className="fas fa-square" />
                    <div className="media-body">
                      <strong>Step-1: </strong>
                      Enter the URL of the website or LinkedIn company page.
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-square" />
                    <div className="media-body">
                      <strong>Step-2: </strong>
                      Click 'Extract' and let our tool do the magic.
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-square" />
                    <div className="media-body">
                      <strong>Step-3: </strong>
                      Receive a detailed report with all available contact
                      information.
                    </div>
                  </li>
                </ul>
                {/* <a className="btn-solid-reg page-scroll" href="sign-up.html">
                  SIGN UP
                </a> */}
              </div>{" "}
              {/* end of text-container */}
            </div>{" "}
            {/* end of col */}
            <div className="col-lg-6">
              <div className="image-container">
                <img
                  className="img-fluid"
                  src="images/details.png"
                  alt="alternative"
                />
              </div>{" "}
              {/* end of image-container */}
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
      <div className="cards-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="above-heading">Benefits</div>
              <h2 className="h2-heading">Why Choose Us</h2>
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
          <div className="row">
            <div className="col-lg-12">
              {/* Card */}
              <div className="card">
                <div className="card-image">
                  <img
                    className="img-fluid"
                    src="images/description-1.png"
                    alt="alternative"
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title">Free to Use</h4>
                  <p>Access powerful data extraction without any cost.</p>
                </div>
              </div>
              {/* end of card */}
              {/* Card */}
              <div className="card">
                <div className="card-image">
                  <img
                    className="img-fluid"
                    src="images/description-2.png"
                    alt="alternative"
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title">Comprehensive Data</h4>
                  <p>Get all available contact details from a single search.</p>
                </div>
              </div>
              {/* end of card */}
              {/* Card */}
              <div className="card">
                <div className="card-image">
                  <img
                    className="img-fluid"
                    src="images/description-3.png"
                    alt="alternative"
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title">User-Friendly</h4>
                  <p>Simple and intuitive interface for easy use.</p>
                </div>
              </div>
              {/* end of card */}
              <div className="card">
                <div className="card-image">
                  <img
                    className="img-fluid"
                    src="images/5057948.jpg"
                    alt="alternative"
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title">Time-Saving</h4>
                  <p>Quickly gather information without manual effort.</p>
                </div>
              </div>
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
      {/* end of basic-1 */}
      {/* end of details */}
      {/* Video */}
      <div id="video" className="basic-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* Video Preview */}
              <div className="image-container">
                <div className="video-wrapper">
                  <a
                    className="popup-youtube"
                    href="https://www.youtube.com/watch?v=fLCjQJCekTs"
                    data-effect="fadeIn"
                  >
                    <img
                      className="img-fluid"
                      src="images/video-image.png"
                      alt="alternative"
                    />
                    <span className="video-play-button">
                      <span />
                    </span>
                  </a>
                </div>{" "}
                {/* end of video-wrapper */}
              </div>{" "}
              {/* end of image-container */}
              {/* end of video preview */}
              <div className="p-heading">
                What better way to show off Tivo marketing automation saas app
                than presenting you some great situations of each module and
                tool available to users in a video
              </div>
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
      {/* end of basic-2 */}
      {/* end of video */}
      {/* Testimonials */}
      <div className="slider-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="slider-container">
                <div className="swiper-container text-slider">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="image-wrapper">
                        <img
                          className="img-fluid"
                          src="images/testimonial-1.jpg"
                          alt="alternative"
                        />
                      </div>
                      <div className="text-wrapper">
                        <div className="testimonial-text">
                          This tool has revolutionized our lead generation
                          process!
                        </div>
                        <div className="testimonial-author">Sarah P</div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="image-wrapper">
                        <img
                          className="img-fluid"
                          src="images/testimonial-2.jpg"
                          alt="alternative"
                        />
                      </div>
                      <div className="text-wrapper">
                        <div className="testimonial-text">
                          An indispensable tool for marketers and recruiters
                        </div>
                        <div className="testimonial-author">John D.</div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="image-wrapper">
                        <img
                          className="img-fluid"
                          src="images/testimonial-3.jpg"
                          alt="alternative"
                        />
                      </div>
                      <div className="text-wrapper">
                        <div className="testimonial-text">
                          I can't believe it's free! It's so powerful and easy
                          to use.
                        </div>
                        <div className="testimonial-author">Michael S</div>
                      </div>
                    </div>
                    {/* <div className="swiper-slide">
                    <div className="image-wrapper">
                      <img
                        className="img-fluid"
                        src="images/testimonial-4.jpg"
                        alt="alternative"
                      />
                    </div>
                    <div className="text-wrapper">
                      <div className="testimonial-text">
                        Tivo is one of the greatest marketing automation apps out there. I especially love the Reporting Tools module because it gives me such a great amount of information based on little amounts of input gathered in just few weeks of light weight usage. Recommended!
                      </div>
                      <div className="testimonial-author">
                        Ronda Louis - Online Marketer
                      </div>
                    </div>
                  </div> */}
                  </div>
                  <div className="swiper-button-next" />
                  <div className="swiper-button-prev" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {/* end of slider-2 */}
      {/* end of testimonials */}
      <div className="cards-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="above-heading">FAQ</div>
              <h2 className="h2-heading">Frequently Asked Questions</h2>
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
          <div className="row">
            <div className="col-lg-12">
              {/* Card */}
              <div className="card">
                
                <div className="card-body p-3 d-flex flex-column justify-content-center" style={{height:'200px'}}>
                  <h5 className="card-title">Is the tool really free?</h5>
                  <p>Yes, our tool is completely free to use.</p>
                </div>
              </div>
              {/* end of card */}
              {/* Card */}
              <div className="card">
                
                <div className="card-body p-3 d-flex flex-column justify-content-center" style={{height:'200px'}}>
                  <h5 className="card-title">How accurate is the data extraction?</h5>
                  <p>Our tool uses advanced algorithms to ensure the highest accuracy.</p>
                </div>
              </div>
              {/* end of card */}
              {/* Card */}
              <div className="card">
                
                <div className="card-body p-3 d-flex flex-column justify-content-center" style={{height:'200px'}}>
                  <h5 className="card-title">What types of data can I extract?</h5>
                  <p>You can extract emails, phone numbers, social media links, and more from websites and LinkedIn pages.</p>
                </div>
              </div>
              {/* end of card */}
             
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
      {/* Newsletter */}
      <div className="form">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-container">
                <div className="above-heading">NEWSLETTER</div>
                <h2>
                  Stay Updated With The Latest News To Get More Qualified Leads
                </h2>
                {/* Newsletter Form */}
                <form
                  id="newsletterForm"
                  data-toggle="validator"
                  data-focus="false"
                >
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control-input"
                      id="nemail"
                      required=""
                    />
                    <label className="label-control" htmlFor="nemail">
                      Email
                    </label>
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group checkbox">
                    <input
                      type="checkbox"
                      id="nterms"
                      defaultValue="Agreed-to-Terms"
                      required=""
                    />
                    I've read and agree to Tivo's written{" "}
                    <a href="privacy-policy.html">Privacy Policy</a> and{" "}
                    <a href="terms-conditions.html">Terms Conditions</a>
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="form-control-submit-button"
                    >
                      SUBSCRIBE
                    </button>
                  </div>
                  <div className="form-message">
                    <div id="nmsgSubmit" className="h3 text-center hidden" />
                  </div>
                </form>
                {/* end of newsletter form */}
              </div>{" "}
              {/* end of text-container */}
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="icon-container">
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fab fa-facebook-f fa-stack-1x" />
                  </a>
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fab fa-twitter fa-stack-1x" />
                  </a>
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fab fa-pinterest-p fa-stack-1x" />
                  </a>
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fab fa-instagram fa-stack-1x" />
                  </a>
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fab fa-linkedin-in fa-stack-1x" />
                  </a>
                </span>
              </div>{" "}
              {/* end of col */}
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
      {/* end of form */}
      {/* end of newsletter */}
      {/* Footer */}
      <svg
        className="footer-frame"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox="0 0 1920 79"
      >
        <defs>
          <linearGradient id="gradient" x1="100%" y1="100%" x2="10%" y2="10%">
            <stop offset="0%" style={{ stopColor: "rgba(65, 35, 153, 1)" }} />
            <stop offset="0%" style={{ stopColor: "rgba(153, 25, 132, 1)" }} />
          </linearGradient>
          <style
            dangerouslySetInnerHTML={{
              __html: ".cls-2{fill: url(#gradient);} ",
            }}
          />
        </defs>
        <title>footer-frame</title>
        <path
          className="cls-2"
          d="M0,72.427C143,12.138,255.5,4.577,328.644,7.943c147.721,6.8,183.881,60.242,320.83,53.737,143-6.793,167.826-68.128,293-60.9,109.095,6.3,115.68,54.364,225.251,57.319,113.58,3.064,138.8-47.711,251.189-41.8,104.012,5.474,109.713,50.4,197.369,46.572,89.549-3.91,124.375-52.563,227.622-50.155A338.646,338.646,0,0,1,1920,23.467V79.75H0V72.427Z"
          transform="translate(0 -0.188)"
        />
      </svg>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-col first">
                <h4>About Tivo</h4>
                <p className="p-small">
                  We're passionate about designing and developing one of the
                  best marketing apps in the market
                </p>
              </div>
            </div>{" "}
            {/* end of col */}
            <div className="col-md-4">
              <div className="footer-col middle">
                <h4>Important Links</h4>
                <ul className="list-unstyled li-space-lg p-small">
                  <li className="media">
                    <i className="fas fa-square" />
                    <div className="media-body">
                      Our business partners{" "}
                      <a className="white" href="#your-link">
                        startupguide.com
                      </a>
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-square" />
                    <div className="media-body">
                      Read our{" "}
                      <a className="white" href="terms-conditions.html">
                        Terms &amp; Conditions
                      </a>
                      ,{" "}
                      <a className="white" href="privacy-policy.html">
                        Privacy Policy
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>{" "}
            {/* end of col */}
            <div className="col-md-4">
              <div className="footer-col last">
                <h4>Contact</h4>
                <ul className="list-unstyled li-space-lg p-small">
                  <li className="media">
                    <i className="fas fa-map-marker-alt" />
                    <div className="media-body">
                      22 Innovative, San Francisco, CA 94043, US
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-envelope" />
                    <div className="media-body">
                      <a className="white" href="mailto:contact@tivo.com">
                        contact@tivo.com
                      </a>{" "}
                      <i className="fas fa-globe" />
                      <a className="white" href="#your-link">
                        www.tivo.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
      {/* end of footer */}
      {/* end of footer */}
      {/* Copyright */}
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="p-small">Copyright © 2020 </p>
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* enf of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
      {/* end of copyright */}
      {/* end of copyright */}
    </>
  );
}

export default Home;

import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';

function ResetPassword() {
  const [formData, setFormData] = useState({ email: '', otp: '', newPassword: '' });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${config.BASE_URL}/auth/reset-password`, formData);
      setMessage('Password reset successful!');
    } catch (error) {
      setMessage('Error resetting password');
    }
  };

  // return (
  //   <Container className="mt-5">
  //     <h1 className="text-center">Reset Password</h1>
  //     <Form onSubmit={handleSubmit}>
  //       <Form.Group controlId="formEmail">
  //         <Form.Label>Email</Form.Label>
  //         <Form.Control
  //           type="email"
  //           name="email"
  //           value={formData.email}
  //           onChange={handleChange}
  //           required
  //         />
  //       </Form.Group>
  //       <Form.Group controlId="formOtp">
  //         <Form.Label>OTP</Form.Label>
  //         <Form.Control
  //           type="text"
  //           name="otp"
  //           value={formData.otp}
  //           onChange={handleChange}
  //           required
  //         />
  //       </Form.Group>
  //       <Form.Group controlId="formNewPassword">
  //         <Form.Label>New Password</Form.Label>
  //         <Form.Control
  //           type="password"
  //           name="newPassword"
  //           value={formData.newPassword}
  //           onChange={handleChange}
  //           required
  //         />
  //       </Form.Group>
  //       <Button type="submit" variant="primary" className="mt-3">Reset Password</Button>
  //     </Form>
  //     {message && <Alert variant="info" className="mt-3">{message}</Alert>}
  //   </Container>
  // );

  return (
    <>
      {/* Header */}
      <header id="header" className="ex-2-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Forgot Password</h1>
              <p className="mt-3">Remember your password? <Link to="/login">Login</Link></p>
              {/* Login Form */}
              <div className="form-container">
                <form id="logInForm" data-toggle="validator" data-focus="false" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                     type="email"
                     className="form-control-input"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <label className="label-control" htmlFor="lemail">
                      Email
                    </label>
                    <div className="help-block with-errors" />
                  </div>


                  <div className="form-group">
                    <input
                     type="text"
                     className="form-control-input"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <label className="label-control" htmlFor="lemail">
                    OTP
                    </label>
                    <div className="help-block with-errors" />
                  </div>


                  <div className="form-group">
                    <input
                     type="password"
                               name="newPassword"
                               className="form-control-input"
                               value={formData.newPassword}
                               onChange={handleChange}
                               required
                    />
                    <label className="label-control" htmlFor="lemail">
                    New Password
                    </label>
                    <div className="help-block with-errors" />
                  </div>
                  

                 
                  
                  <div className="form-group">
                    <button type="submit" className="form-control-submit-button">
                    Reset Password
                    </button>
                  </div>
                  <div className="form-message">
                  {message && <Alert variant="info" className="mt-3">{message}</Alert>}
                  </div>
                </form>
              </div>
              {/* end of form container */}
            </div>
            {/* end of col */}
          </div>
          {/* end of row */}
        </div>
        {/* end of container */}
      </header>
      {/* end of ex-header */}
      {/* end of header */}
    </>
  );



}

export default ResetPassword;

import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Layout from './Layout';

const PrivateRouteWithLayout = () => {
  const { user } = useContext(AuthContext);
  const token = localStorage.getItem('token');

  if (!user && !token) {
    
    return <Navigate to="/login" />;
  }
  else{
console.log(user);
  return <Layout><Outlet /></Layout>;}
};

export default PrivateRouteWithLayout;

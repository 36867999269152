import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Form,
  Button,
  Table
} from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';
import { FaDownload } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'react-bootstrap/Spinner';

function AdminSocial() {
  const [message, setMessage] = useState('');
  const [modalData, setModalData] = useState([]);
  const [statusFilter, setStatusFilter] = useState('All URLs');
  const token = localStorage.getItem('token');
  const [page, setPage] = useState(1);
  const [TotalRow, setTotalRow] = useState(0);
  const [downloadData, setDownloadData] = useState([]);
  const [downloadCSVData, setDownloadCSVData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [disable, setDisable] = useState(false);



  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);


  useEffect(() => {
    fetchData();
  }, []);





  const fetchData = async () => {

    try {

      const res = await axios.get(`${config.BASE_URL}/admin/data/social?page=${page}&limit=100`,
        { headers: { 'x-auth-token': token } });
      // console.log(res.data.total);
      setTotalData(res.data.total);
      setModalData(res.data.socialData);
    } catch (error) {
      console.error('Error fetching file data:', error);
    }
  };

  const fetchMore = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/admin/data/social?page=${page + 1}&limit=100`, { headers: { 'x-auth-token': token } });
      setPage(page + 1);
      console.log("page", page);
      setModalData(modalData.concat(res.data.socialData));
    }
    catch (error) {
      console.error('Error fetching file data:', error);
    }
  };

  useEffect(() => {
    if (downloadCSVData.length !== 0) {
      const visibleData = filterDownloadCSVData().map(({ URL, Status, ResTime, Email, Phone, Facebook, Twitter, LinkedIn, Instagram, YouTube, createdAt }) => ({
        URL,
        Status,
        ResTime,
        Email,
        Phone,
        Facebook,
        Twitter,
        LinkedIn,
        Instagram,
        YouTube,
        createdAt
      }));
      const csv = Papa.unparse(visibleData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `${downloadCSVData[0]?.fileName || 'data'}.csv`);
      setDisable(false);
    }
  }, [downloadCSVData])

  useEffect(() => {
    if (downloadData.length !== 0) {
      const visibleData = filterDownloadData().map(({ URL, Status, ResTime, Email, Phone, Facebook, Twitter, LinkedIn, Instagram, YouTube, createdAt }) => ({
        URL,
        Status,
        ResTime,
        Email,
        Phone,
        Facebook,
        Twitter,
        LinkedIn,
        Instagram,
        YouTube,
        createdAt
      }));
      try {
        const ws = XLSX.utils.json_to_sheet(visibleData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${downloadData[0]?.fileName || 'data'}.xlsx`);
      } catch (error) {
        console.error('Error generating Excel file:', error);
      }
      setDisable(false);
    }
  }, [downloadData])



  const handleDownloadCSV = async () => {
    // console.log("hello download");
    setDisable(true);
    const res = await axios.get(`${config.BASE_URL}/admin/data/social?limit=${totalData}`, {
      headers: { 'x-auth-token': token }
    });
    setDownloadCSVData(res.data.socialData);
    // console.log(res.data.socialData,"df",downloadCSVData)


  };

  const handleDownloadExcel = async () => {
    setDisable(true);
    const res = await axios.get(`${config.BASE_URL}/admin/data/social?limit=${totalData}`, {
      headers: { 'x-auth-token': token }
    });
    setDownloadData(res.data.socialData);


  };

  const filterModalData = () => {
    if (statusFilter === 'All URLs') {
      return modalData;
    }
    if (statusFilter === 'Success URLs') {
      return modalData.filter(item => item.Status === 'Success');
    }
    if (statusFilter === 'Error URLs') {
      return modalData.filter(item => item.Status === 'Error');
    }
    return modalData;
  };

  const filterDownloadCSVData = () => {
    if (statusFilter === 'All URLs') {
      return downloadCSVData;
    }
    if (statusFilter === 'Success URLs') {
      return downloadCSVData.filter(item => item.Status === 'Success');
    }
    if (statusFilter === 'Error URLs') {
      return downloadCSVData.filter(item => item.Status === 'Error');
    }
    return downloadCSVData;
  };

  const filterDownloadData = () => {
    if (statusFilter === 'All URLs') {
      return downloadData;
    }
    if (statusFilter === 'Success URLs') {
      return downloadData.filter(item => item.Status === 'Success');
    }
    if (statusFilter === 'Error URLs') {
      return downloadData.filter(item => item.Status === 'Error');
    }
    return downloadData;
  };


  return (
    <div style={{ marginTop: '20px' }}>
      <h4 className='my-2 ml-4 font-weight-bold'>All Social Data</h4>

      <div style={{ padding: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <strong className='ml-4'>Total Data: </strong> {totalData} <br />
        <Form.Group className='d-flex justify-content-between align-items-center'>
          <div>
            <div style={{ display: 'inline-block', marginRight: '10px' }} onClick={() => document.getElementById('success-urls').click()}>
              <Form.Check
                inline
                type="radio"
                label="Success URLs"
                name="statusFilter"
                id="success-urls"
                onChange={() => setStatusFilter('Success URLs')}
              />
            </div>
            <div style={{ display: 'inline-block', marginRight: '10px' }} onClick={() => document.getElementById('error-urls').click()}>
              <Form.Check
                inline
                type="radio"
                label="Error URLs"
                name="statusFilter"
                id="error-urls"
                onChange={() => setStatusFilter('Error URLs')}
              />
            </div>
            <div style={{ display: 'inline-block', marginRight: '10px' }} onClick={() => document.getElementById('all-urls').click()}>
              <Form.Check
                inline
                type="radio"
                label="All URLs"
                name="statusFilter"
                id="all-urls"
                defaultChecked
                onChange={() => setStatusFilter('All URLs')}
              />
            </div>
          </div>
          <div>
            <Button variant="primary" onClick={handleDownloadCSV} className="ml-2 btn-solid-lg" disabled={disable} style={{ background: 'linear-gradient(135deg, rgb(28 28 29) 0%, rgb(161 154 160) 100%)', border: 'none' }}>{disable ? (<Spinner size="sm" animation="border" />) : (<> <FaDownload /> CSV </>)}</Button>
            <Button variant="primary" onClick={handleDownloadExcel} className="ml-2 btn-solid-lg" disabled={disable} style={{ background: 'linear-gradient(135deg, rgb(28 28 29) 0%, rgb(161 154 160) 100%)', border: 'none' }}>{disable ? (<Spinner size="sm" animation="border" />) : (<> <FaDownload /> Excel </>)}</Button>
          </div>
        </Form.Group>
      </div>

      <div id='tableWrapper' className="table-wrapper" style={{
        maxHeight: '70vh', height: '100%', '@media (max-width: 1080px)': {
          maxHeight: '70vh'
        }
      }}>

        <InfiniteScroll
          dataLength={modalData.length}
          next={fetchMore}
          hasMore={modalData.length !== totalData}
          loader={<h6>Loading...</h6>}
          scrollableTarget="tableWrapper"
          style={{ overflow: "unset" }}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          scrollThreshold={1}
        >
          <Table striped bordered hover>
            <thead className="sticky-header">
              <tr>
                <th><span className="d-inline-flex align-items-center"> SNo.</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-link-45deg me-2"></i> URL</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-info-circle me-2"></i> Status</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-hourglass-split me-2"></i> ResTime</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-envelope me-2"></i> Email</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-telephone me-2"></i> Phone</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-facebook me-2"></i> Facebook</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-twitter me-2"></i> Twitter</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-linkedin me-2"></i> LinkedIn</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-instagram me-2"></i> Instagram</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-youtube me-2"></i> YouTube</span></th>
                <th><span className="d-inline-flex align-items-center"><i className="bi bi-calendar me-2"></i> createdAt</span></th>
              </tr>

            </thead>

            <tbody>

              {filterModalData().map((item, index) => (
                <tr className='Sheet-data' key={item._id}>
                  <td>{index + 1} </td>
                  <td>{item.URL && item.URL.length >= 30 ? <textarea disabled value={item.URL} /> : item.URL}</td>
                  <td>{item.Status && item.Status.length >= 30 ? <textarea disabled value={item.Status} /> : item.Status}</td>
                  <td>{item.ResTime && item.ResTime.length >= 30 ? <textarea disabled value={item.ResTime} /> : item.ResTime}</td>
                  <td>{item.Email && item.Email.length >= 30 ? <textarea disabled value={item.Email} /> : item.Email}</td>
                  <td>{item.Phone && item.Phone.length >= 30 ? <textarea disabled value={item.Phone} /> : item.Phone}</td>
                  <td>{item.Facebook && item.Facebook.length >= 30 ? <textarea disabled value={item.Facebook} /> : item.Facebook}</td>
                  <td>{item.Twitter && item.Twitter.length >= 30 ? <textarea disabled value={item.Twitter} /> : item.Twitter}</td>
                  <td>{item.LinkedIn && item.LinkedIn.length >= 30 ? <textarea disabled value={item.LinkedIn} /> : item.LinkedIn}</td>
                  <td>{item.Instagram && item.Instagram.length >= 30 ? <textarea disabled value={item.Instagram} /> : item.Instagram}</td>
                  <td>{item.YouTube && item.YouTube.length >= 30 ? <textarea disabled value={item.YouTube} /> : item.YouTube}</td>

                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                </tr>
              ))}

            </tbody>

          </Table>
        </InfiniteScroll>
      </div>

    </div>
  );
}

export default AdminSocial;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { FaUser, FaEnvelope, FaKey, FaSave } from 'react-icons/fa';
import config from '../../config';
import 'bootstrap/dist/css/bootstrap.min.css';

function Profile() {
  const [profile, setProfile] = useState({});
  const [name, setName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await axios.get(`${config.BASE_URL}/user/profile`, {
          headers: { 'x-auth-token': token }
        });
        setProfile(res.data);
        setName(res.data.name);
      } catch (error) {
        console.error('Error fetching profile', error);
      }
    };

    fetchProfile();
  }, [token]);

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  const handleUpdateProfile = async () => {
    try {
      const res = await axios.put(
        `${config.BASE_URL}/user/profile`,
        { name },
        { headers: { 'x-auth-token': token } }
      );
      setProfile(res.data);
      setSuccessMessage('Profile updated successfully.');
      setErrorMessage('');
    } catch (error) {
      console.error('Error updating profile', error);
      setErrorMessage('Error updating profile.');
      setSuccessMessage('');
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password do not match.');
      setSuccessMessage('');
      return;
    }
    try {
      await axios.put(
        `${config.BASE_URL}/user/change-password`,
        { oldPassword, newPassword },
        { headers: { 'x-auth-token': token } }
      );
      setSuccessMessage('Password changed successfully.');
      setErrorMessage('');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error changing password', error);
      setErrorMessage('Error changing password.');
      setSuccessMessage('');
    }
  };

  return (
    <Container >
 

      <h1 className="text-left mt-5 mb-5" style={{ fontSize: '2rem', display: 'flex', alignItems: 'center' }}>
        <FaUser style={{ marginRight: '10px' }} /> Profile
      </h1>

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Row>
        <Col md={6} className="mb-4">
          <Form>
            <Form.Group controlId="formName">
              <Form.Label><FaUser /> Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label><FaEnvelope /> Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={profile.email}
                readOnly
              />
            </Form.Group>
            <Button className="mt-3 btn-solid-lg" variant="primary" onClick={handleUpdateProfile}>
              <FaSave /> Update Profile
            </Button>
          </Form>
        </Col>
        <Col md={6}>
          <h2>Change Password</h2>
          <Form>
            <Form.Group controlId="formOldPassword">
              <Form.Label><FaKey /> Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter old password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNewPassword" className="mt-3">
              <Form.Label><FaKey /> New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formConfirmPassword" className="mt-3">
              <Form.Label><FaKey /> Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            <Button className="mt-3 btn-solid-lg" variant="primary" onClick={handleChangePassword}>
              <FaSave /> Change Password
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Profile;
